.jump-menu-wrapper {
    background-color: #13293d;
    height: 3.5em;
    position: fixed;
    width: inherit;
    z-index: 1;
}

.jump-menu-item {
    display: inline-block;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 100%;
    color: #fefefe;
}

@media screen and (max-width: 979px) {
    .jump-menu-wrapper {
        display: none;
    }
  }