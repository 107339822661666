.slide-btn {
    cursor: pointer;
    background-color: #f0f8ff;
    border: none;
    font-size: 2.5rem;
    color: #bbe1f7;
    outline: none;
}

.slide-btn:hover {
    background-color: #bbe1f7;
    color: #f0f8ff;
}