.proj-slide-wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #f0f8ff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@media screen and (max-width: 549px) {
    .proj-slide-wrap {
        display: none;
    }
}