.contact-text-wrapper > h1, .contact-text-wrapper > p {
    text-align: center;
}

.contact-text-wrapper li {
    list-style-position: inside;
    list-style-type: none;
}

.contact-text-wrapper ul {
    text-align: center;
    padding-inline-start: 0;
}

.contact-text-wrapper a {
    color: white;
}