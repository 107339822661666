body {
  margin: 0;
  background-color: #247ba0;
  font-size: 62.5%;
  color: #E8F1F2;
}

html {
  scroll-behavior: smooth;
}

#root {
  display: flex;
  justify-content: center;
}

.border {
  border: 2px solid black;
}

.specific-project-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 70em;
  padding: 2.5em;
}

.top-comp-space {
  padding-top: 3.5em;
}

.experience-basic {
  max-width: 52em;
  background-color: #e8f1f2;
  color: #13293d;
  margin: 2em 0 2em 0;
  padding: 2em;
  border-radius: .5em;
}

.experience-basic > h2 {
  margin-top: 0;
}

.experience-basic ul li {
  list-style-type: none;
}

.section-wrap > h1 {
  margin-top: 0;
  padding: 0.67em 3em 0 3em;
}

.info-top {
    height: 100%;
    display: table;
}

.info-top > * {
    display: table-cell;
    vertical-align: middle;
}

.info-top > span {
    font-size: .77rem;
    padding: .25em 1.25em .25em .25em;
}

.jmp-pnt::before {
  content: "";
  display: block;
  height: 3.4em; /* fixed header height*/
  margin:-3.4em 0 0; /* negative fixed header height */
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.65rem;
}

p {
  font-size: .95rem;
  margin: 0;
}

li {
  font-size: .85rem;
}

@media screen and (max-width: 979px) {
  .top-comp-space {
    padding-top: 0;
  }
}

@media screen and (max-width: 840px) {
  .experience-basic {
    max-width: 25em;
  }
}

@media screen and (min-width: 980px) and (max-width:1089px) {
  .specific-project-wrapper {
    width: 60em;
  }
}

@media screen and (min-width: 550px) and (max-width: 979px) {
  .specific-project-wrapper {
    width: 25em;
  }
}

@media screen and (max-width: 450px) {
  .section-wrap > h1 {
    padding: 0.67em 0 0 0;
  }

}