.about-comp-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #13293d;
}

@media screen and  (max-width:1089px) {
    .about-comp-wrapper {
        flex-direction: column;
        align-items: center;
    }
}