.project-txt-wrapper {
    display: inline-block;
    height: 485px;
    max-width: 29em;
    border-radius: .5em;
    padding: 0 2.5em 0 2.5em;
    margin: .5em auto;
}

@media screen and (min-width: 980px) and (max-width:1089px) {
    .project-txt-wrapper {
        height: 385px;
    }
}

@media screen and (min-width: 550px) and (max-width:979px) {
    .project-txt-wrapper {
        height: auto;
    }
}

@media screen and (max-width: 549px) {
    .project-txt-wrapper {
        height: auto;
        display: block;
        padding-top: .05em;
        padding-bottom: .1em;
        background-color: #f0f8ff;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
}