.to-project-btn {
    text-decoration: none;
}

.to-project-btn > button {
    display: block;
    background-color: #1b98e0;
    color: #e8efe7;
    border: none;
    font-size: 1rem;
    padding: .75em;
    border-radius: 3px;
    cursor: pointer;
    margin: 1em auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}